import { configureStore } from "@reduxjs/toolkit";
import restaurants from "../models/restaurants/store";
import auth from "../models/auth/store";
import workers from "../models/workers/store";
import stories from "../models/stories/store";
import ui from "../models/ui/store";
import accountCampaigns from "../models/accountCampaigns/store";
import accounts from "../models/accounts/store";

export const store = configureStore({
  reducer: {
    restaurants,
    auth,
    workers,
    stories,
    ui,
    accountCampaigns,
    accounts,
  },
});
