const menuItems = [
  {
    path: "/admin",
    name: "Restaurantes",
    admin: true,
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    admin: true,
  },
  {
    path: "/admin/workers",
    name: "Trabajadores",
    admin: true,
  },
  {
    path: "/admin/stories",
    name: "Stories",
    admin: true,
  },
  {
    path: "/admin/story-validation",
    name: "Validador QR",
    admin: false,
    worker: true,
  },
  {
    path: "/admin/instructions",
    name: "Instrucciones",
    admin: false,
    worker: true,
  },
];

export default menuItems;
