import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  Tooltip as MuiTooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";
import useRestaurants from "../../../../models/restaurants/useRestaurants";
import { buildValidationSchema } from "./validations";
import FormTextInput from "../../../../components/TextInput/Form";
import GPlaces from "../../../../components/GPlaces";

import "./styles.scss";
import FormSwitch from "../../../../components/Switch/form";

const RestaurantInfo = () => {
  const navigate = useNavigate();
  const { id: restaurantId } = useParams();
  const {
    restaurant,
    setRestaurant,
    fetchRestaurant,
    createRestaurant,
    updateRestaurant,
  } = useRestaurants();
  const [address, setAddress] = useState();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(buildValidationSchema()),
    defaultValues: {
      name: restaurant?.name || "",
      ig_account: restaurant?.ig_account || "",
      participation_requirement: restaurant?.participation_requirement || "",
      participation_rate: restaurant?.participation_rate || 1,
      full_address: restaurant?.full_address || "",
      has_delivery: restaurant?.has_delivery || false,
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const parsedData = {
      ...data,
      ig_account: data.ig_account.replace("@", "").toLowerCase(),
      ...address,
      full_address: address.fullAddress,
    };
    try {
      if (restaurantId) {
        await updateRestaurant(restaurantId, parsedData);
        toast.success("Restaurante editado correctamente.");
      } else {
        const createdRestaurant = await createRestaurant(parsedData);
        toast.success(
          "Restaurante creado correctamente. Ya puedes añadir recompensas"
        );
        navigate(`./../${createdRestaurant.slug}?tab=1`);
      }
    } catch (e) {
      if (restaurantId) {
        toast.error("Error al editar el restaurante.");
      } else {
        toast.error("Error al crear el restaurante.");
      }
      console.log(e);
    }
  };

  const resetForm = () => {
    if (restaurant) {
      reset({
        name: restaurant.name,
        full_address: restaurant.full_address,
        ig_account: restaurant.ig_account,
        participation_requirement: restaurant.participation_requirement,
        participation_rate: restaurant.participation_rate,
        has_food_validation: restaurant.has_food_validation,
        has_delivery: restaurant.has_delivery,
      });
      setAddress({
        ...restaurant.address,
        fullAddress: restaurant.full_address,
      });
    }
  };

  useEffect(() => {
    resetForm();
  }, [restaurant]);

  return (
    <div className="RestaurantInfo">
      <FormProvider {...methods} autoComplete={false}>
        <div className="RestaurantInfo__title">Datos del restaurante</div>
        <div className="RestaurantInfo__form__inputs">
          <div>
            <div className="RestaurantInfo__form__label">
              Nombre del restaurante
              <MuiTooltip title="El nombre del restaurante tiene que ser único, sugerimos añadir la calle o algún otro dato distintivo.">
                <InfoOutlined fontSize="small" />
              </MuiTooltip>
            </div>
            <FormTextInput
              control={control}
              name="name"
              required={true}
              placeholder="Ej, Burger King Sagasta"
            />
          </div>
          <div>
            <div className="RestaurantInfo__form__label">
              Dirección del restaurante
            </div>
            <GPlaces
              required
              name="address"
              onChange={setAddress}
              initialValue={restaurant?.full_address || ""}
            />
          </div>
          <div>
            <div className="RestaurantInfo__form__label">
              Cuenta de Instagram{" "}
              <MuiTooltip title="Cuenta de instagram de tu restaurante a la que mencionarán en la stories">
                <InfoOutlined fontSize="small" />
              </MuiTooltip>
            </div>
            <FormTextInput
              control={control}
              name="ig_account"
              placeholder="Añadie tu cuenta de instagram"
              required={true}
            />
          </div>
        </div>
        <div className="RestaurantInfo__title RestaurantInfo__title--second">
          Configuración de participación
        </div>
        <div
          className="RestaurantInfo__form__inputs"
          style={{ marginBottom: "24px" }}
        >
          <div>
            <div className="RestaurantInfo__form__label">
              Permitir solo fotos de comida
              <MuiTooltip title="Si esta opción está activa, la IA solo dará por válidas las stories en las que aparezca comida.">
                <InfoOutlined fontSize="small" />
              </MuiTooltip>
            </div>
            <FormSwitch control={control} name="has_food_validation" />
          </div>
          <div>
            <div className="RestaurantInfo__form__label">
              Permitir delivery
              <MuiTooltip title="Esto permite a los clientes participar desde su casa y canjear el código en el restaurante.">
                <InfoOutlined fontSize="small" />
              </MuiTooltip>
            </div>
            <FormSwitch control={control} name="has_delivery" />
          </div>
        </div>
        <div className="RestaurantInfo__form__inputs">
          <div>
            <div className="RestaurantInfo__form__label">
              Frecuencia de participación (días)
              <MuiTooltip title="Esta cifra indica el número de días que el cliente debe esperar para participar de nuevo. El número de días debe ser mínimo 1 ya que las stories duran 24 horas.">
                <InfoOutlined fontSize="small" />
              </MuiTooltip>
            </div>
            <FormTextInput
              control={control}
              name="participation_rate"
              type="number"
              required={true}
            />
          </div>
          <div>
            <div className="RestaurantInfo__form__label">
              Requisito para participar (Opcional)
              <MuiTooltip title="Este es el requisito mínimo que impone el restaurante para participar. El control de que se cumplan estos requisitos queda en manos de los trabajadores del restaurante">
                <InfoOutlined fontSize="small" />
              </MuiTooltip>
            </div>
            <FormTextInput
              control={control}
              name="participation_requirement"
              placeholder="Ej, Pedir un menú hamburguesa"
              required={true}
              multiline={true}
            />
          </div>
        </div>
        <div className="RestaurantInfo__form__actions">
          <Link to="../">
            <Button variant="outlined">Cancelar</Button>
          </Link>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={
              !address?.fullAddress || !isValid || !isDirty || isSubmitting
            }
          >
            Guardar
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default RestaurantInfo;
