import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";

import Accordion from "../../components/Accordion";

import faq from "./faq";
import handImage from "./images/hand.png";
import phoneImage from "./images/iphone.png";
import section21 from "./images/section2-1.svg";
import section22 from "./images/section2-2.svg";
import section23 from "./images/section2-3.svg";
import section24 from "./images/section2-4.svg";
import checkbox from "./images/checkbox.svg";
import dashboard from "./images/dashboard.png";
import footer from "./images/footer.svg";

import "./styles.scss";

const WhatIsWubalu = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="WIW">
      <div className="WIW__header-background">
        <div className="WIW__header WIW__content-wrapper">
          <div className="WIW__header__content">
            <div className="WIW__header__title">
              Convierte tu restaurante en una tendencia viral.
            </div>
            <div className="WIW__header__subtitle">
              Con Wubalu, tus clientes se convierten en influencers,
              compartiendo sus experiencias en redes sociales, llenando tus mesas y aumentando tus ventas.
            </div>
            <div className="WIW__header__cta">
              <Link to="../create-account">
                <Button variant="contained">Crea tu cuenta ahora</Button>
              </Link>
              <div className="WIW__header__cta__tip">
                Garantía total durante los tres primeros meses.
              </div>
            </div>
            <div className="WIW__header__images">
              <div className="WIW__header__images__1">
                <img src={handImage} />
              </div>
              <div className="WIW__header__images__2">
                <img src={phoneImage} />
              </div>
            </div>
          </div>
          <div className="WIW__header__video">
            <YouTube videoId={"ntvuv1RPd1o"} />
          </div>
        </div>
      </div>
      <div className="WIW__section1">
        <div className="WIW__content-wrapper">
          <div className="WIW__section1__title">
            <span>El poder</span> de la recomendación
          </div>
          <div className="WIW__section1__list">
            <div className="WIW__section1__item">
              <div className="WIW__section1__item__title">
                <span>61% </span>de los clientes
              </div>
              <div className="WIW__section1__item__text">
                <b>Consultan las redes sociales </b>de sus amigos para decidir
                dónde ir a comer o beber. Wubalu convierte cada visita en una
                recomendación para tus futuros clientes.
              </div>
            </div>
            <div className="WIW__section1__item">
              <div className="WIW__section1__item__title">
                <span>69% </span>de los clientes
              </div>
              <div className="WIW__section1__item__text">
                <b>Recomiendan un local </b>después de una buena experiencia.
                Wubalu facilita que esas recomendaciones lleguen a miles de
                personas.
              </div>
            </div>
            <div className="WIW__section1__item">
              <div className="WIW__section1__item__title">
                <span>83% </span>de los clientes
              </div>
              <div className="WIW__section1__item__text">
                <b>Confía más en las recomendaciones de amigos y familiares </b>
                que en cualquier otra forma de publicidad.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="WIW__section2-background">
        <div className="WIW__section2 WIW__content-wrapper">
          <div className="WIW__section2__title">
            <span>Cómo funciona </span>Wubalu
          </div>
          <div className="WIW__section2__list">
            <div className="WIW__section2__item">
              <div className="WIW__section2__item__image">
                <img src={section21} />
              </div>
              <div className="WIW__section2__item__text">
                Tus clientes visitan el restaurante.
              </div>
            </div>
            <div className="WIW__section2__item">
              <div className="WIW__section2__item__image">
                <img src={section22} />
              </div>
              <div className="WIW__section2__item__text">
                Suben una story mencionándote en Instagram y recomendándote a
                sus amigos.
              </div>
            </div>
            <div className="WIW__section2__item">
              <div className="WIW__section2__item__image">
                <img src={section23} />
              </div>
              <div className="WIW__section2__item__text">
                Reciben un premio configurado por el restaurante.
              </div>
            </div>
            <div className="WIW__section2__item">
              <div className="WIW__section2__item__image">
                <img src={section24} />
              </div>
              <div className="WIW__section2__item__text">
                Sus amigos ven la recomendación y visitan el local.
              </div>
            </div>
          </div>
          <div className="WIW__section2__cta">
            <Link to="../create-account">
              <Button variant="contained">Empieza ahora</Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="WIW__section3-background">
        <div className="WIW__section3 WIW__content-wrapper">
          <div className="WIW__section3__title">
            <span>Ventajas </span>de usar Wubalu
          </div>
          <div className="WIW__section3__list">
            <div className="WIW__section3__item">
              <div className="WIW__section3__item__image">
                <img src={checkbox} />
              </div>
              <div className="WIW__section3__item__text">
                Decenas de stories cada semana que te convierten en <b>tendencia</b>
              </div>
            </div>
            <div className="WIW__section3__item">
              <div className="WIW__section3__item__image">
                <img src={checkbox} />
              </div>
              <div className="WIW__section3__item__text">
                <b>Aumenta la recurrencia </b>de clientes habituales.
              </div>
            </div>
            <div className="WIW__section3__item">
              <div className="WIW__section3__item__image">
                <img src={checkbox} />
              </div>
              <div className="WIW__section3__item__text">
                Dispara tu <b>presencia digital</b>
              </div>
            </div>
            <div className="WIW__section3__item">
              <div className="WIW__section3__item__image">
                <img src={checkbox} />
              </div>
              <div className="WIW__section3__item__text">
                <b>Garantía total:</b> si no ves resultados te devolvemos el dinero.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="WIW__section4">
        <div className="WIW__section4__wrapper WIW__content-wrapper">
          <div className="WIW__section4__content">
            <div className="WIW__section4__title">
              <span>Elige el plan que mas se adapte a tus necesidades</span>
              <br />
              Nuestros planes están diseñados para adaptarse a todos los restaurantes.
            </div>
            <div className="WIW__section4__subtitle">
              Consulta los detalles en nuestra sección de precios.
            </div>
            <div className="WIW__section4__ctas">
              <Link to="../pricing" className="WIW__section4__ctas__1">
                <Button variant="contained">Ver precios completos</Button>
              </Link>
              <Link to="../create-account" className="WIW__section4__ctas__2">
                <Button variant="contained">Crea tu cuenta ahora</Button>
              </Link>
            </div>
          </div>
          <div className="WIW__section4__image">
            <img src={dashboard} />
          </div>
        </div>
      </div>
      <div className="WIW__faqs">
        <div className="WIW__faqs__wrapper">
          <div className="WIW__faqs__title">Preguntas frecuentes</div>
          <Accordion items={faq} />
        </div>
      </div>
      <div className="WIW__footer">
        <div className="WIW__footer__wrapper WIW__content-wrapper">
          <div className="WIW__footer__title">¿Listo para empezar?</div>
          <div className="WIW__footer__image">
            <img src={footer} />
          </div>
          <div className="WIW__footer__content">
            <div className="WIW__footer__item">
              <div className="WIW__footer__text">
                Abre tu cuenta ahora
                <br />
                <span>y empieza a disfrutar de todo el potencial de Wubalu.</span>
              </div>
              <div className="WIW__footer__cta">
                <Link to="../create-account" className="WIW__section4__ctas__2">
                  <Button variant="contained">Crea tu cuenta ahora</Button>
                </Link>
              </div>
            </div>
            <div className="WIW__footer__item">
              <div className="WIW__footer__text">
                <span>¿Tienes dudas?</span>
                <br />
                Solicita una demo con uno de nuestros expertos y te mostraremos
                cómo Wubalu puede transformar tu restaurante.
              </div>
              <div>
                <a href="https://calendly.com/wubalu/30min" target="_blank">
                  <Button variant="contained">Solicitar Demo</Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="WIW__ctas">
        <div className="WIW__ctas__1">
          <a href="https://calendly.com/wubalu/30min" target="_blank">
            <Button variant="contained">
              {isSmallScreen
                ? "Solicitar Demo"
                : "¿Tienes dudas? Solicita una demo"}
            </Button>
          </a>
        </div>
        <div className="WIW__ctas__2">
          <Link to="../create-account" className="WIW__section4__ctas__2">
            <Button variant="contained">
              Empieza ahora
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhatIsWubalu;
