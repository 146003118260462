import { useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import useRestaurants from "../../../models/restaurants/useRestaurants";
import RestaurantInfo from "./GeneralInfo";
import RestaurantCampaigns from "./Campaigns";
import RestaurantDisplays from "./Displays";

import "./styles.scss";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <div className="RestaurantDetail__tab">{children}</div>
      )}
    </div>
  );
};

const RestaurantDetail = () => {
  const { id: restaurantId } = useParams();
  const { restaurant, fetchRestaurant, setRestaurant } = useRestaurants();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    restaurantId && fetchRestaurant(restaurantId);
    return () => {
      setRestaurant({});
    };
  }, [restaurantId]);

  useEffect(() => {
    const tabIndex = parseInt(searchParams.get("tab")) || 0;
    if (tabIndex) setValue(tabIndex);
    return () => {
      setSearchParams({});
    };
  }, [searchParams]);

  return (
    <div className="RestaurantDetail">
      <div className="RestaurantDetail__header">
        <h1>Ficha de restaurante</h1>
      </div>
      {restaurantId && !restaurant?.is_active && (
        <div className="RestaurantDetail__inactive">
          <div className="RestaurantDetail__inactive__title">
            Este restaurante no está activo.
          </div>
          <div>
            Para que un restaurante esté activo tiene que tener al menos una
            recompensa activa.
          </div>
        </div>
      )}
      <div className="RestaurantDetail__container">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Información general" />
          <Tab label="Recompensas" disabled={!restaurantId} />
          {/*<Tab label="QRs" disabled={!restaurantId} />*/}
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <RestaurantInfo />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <RestaurantCampaigns />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <RestaurantDisplays />
        </CustomTabPanel>
      </div>
    </div>
  );
};

export default RestaurantDetail;
