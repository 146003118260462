import { useDispatch, useSelector } from "react-redux";

import {
  showLoader,
  hideLoader,
  showPaymentPopup,
  hidePaymentPopup,
} from "./store";

const useUi = () => {
  const { loaderCount, paymentPopup } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    showPaymentPopup: (isRequired) =>
      dispatch(showPaymentPopup({ isRequired })),
    hidePaymentPopup: () => dispatch(hidePaymentPopup()),
    paymentPopup,
    isLoaderVisible: loaderCount > 0,
  };
};

export default useUi;
