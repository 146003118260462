import { createSlice } from "@reduxjs/toolkit";

const initialPaymentPopupState = {
  isVisible: false,
  isRequired: true,
};

const initialState = {
  loaderCount: 0,
  paymentPopup: initialPaymentPopupState,
};

export const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showLoader: (state) => {
      state.loaderCount += 1;
    },
    hideLoader: (state) => {
      state.loaderCount -= 1;
    },
    showPaymentPopup: (state, action) => {
      state.paymentPopup.isVisible = true;
      state.paymentPopup.isRequired = !!action.payload.is_required;
    },
    hidePaymentPopup: (state) => {
      state.paymentPopup = initialPaymentPopupState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showLoader, hideLoader, showPaymentPopup, hidePaymentPopup } =
  slice.actions;

export default slice.reducer;
