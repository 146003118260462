import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import useAccounts from "../../models/accounts/useAccounts";
import useAuth from "../../models/auth/useAuth";
import useUi from "../../models/ui/useUi";
import CompanyInfo from "./CompanyInfo";
import PaymentMethod from "./PaymentMethod";

import "./styles.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB);

const PaymentInfoModal = ({ onClose, onPaymentSucceed }) => {
  const [step, setStep] = useState(1);
  const { fetchAccount } = useAccounts();
  const { user } = useAuth();
  const { paymentPopup, hidePaymentPopup } = useUi();

  const handlePaymentSucceed = async () => {
    if (onPaymentSucceed) onPaymentSucceed();
    await fetchAccount(user.restaurant_worker.account);
    hidePaymentPopup();
  };

  return (
    <Dialog
      open={paymentPopup.isVisible}
      onClose={onClose}
      className="PaymentInfoModal"
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <div className="PaymentInfoModal__steps">Paso {step} de 2</div>
        {step === 1 ? (
          <CompanyInfo onSubmitSuccess={() => setStep(2)} />
        ) : (
          <Elements stripe={stripePromise}>
            <PaymentMethod onPaymentSucceed={handlePaymentSucceed} />
          </Elements>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PaymentInfoModal;
