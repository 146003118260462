import useAccounts from "../../models/accounts/useAccounts";
import useAuth from "../../models/auth/useAuth";
import useUi from "../../models/ui/useUi";
import Unauthorized from "../Unauthorized";

const AdminRoute = ({ children }) => {
  const {
    user: { restaurant_worker },
  } = useAuth();
  const { account } = useAccounts();
  const { showPaymentPopup } = useUi();

  if (restaurant_worker && restaurant_worker.is_admin) {
    if (account.id && !account.has_payment_access) {
      showPaymentPopup(true);
    }
    return children;
  }

  return <Unauthorized />;
};

export default AdminRoute;
