import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import warrantyIcon from "./images/warranty.svg";
import starterIcon from "./images/starter.svg";
import proIcon from "./images/pro.svg";

import "./styles.scss";

const Pricing = () => {
  const navigate = useNavigate();

  const handleSelectPlan = (plan) => {
    localStorage.setItem('wubalu_plan', plan);
    navigate('/create-account');
  }

  return (
    <div className="PricingPage">
      <div className="PricingPage__box">
        <div className="PricingPage__title">
          Elige el plan perfecto para<span> llenar tu restaurante y ser la próxima gran tendencia.</span>
        </div>
        <div className="PricingPage__subtitle">No esperes más para empezar a disfrutar de las virtudes de Wubalu. Estás a muy pocos pasos de ser la nueva tendencia.</div>
        <div className="PricingPage__warranty">
          <div className="PricingPage__warranty__image">
            <img src={warrantyIcon} alt="Garantía total" />
          </div>
          <div>
            <div className="PricingPage__warranty__description">
              <span>¡Garantía total!</span> En Wubalu, estamos tan seguros de la efectividad de nuestra herramienta que ofrecemos una garantía de 3 meses. Si después de tres meses no has alcanzado los resultados esperados, te devolvemos todo tu dinero sin hacer preguntas. Queremos que te sientas completamente seguro al invertir en tu negocio con nosotros.
            </div>
          </div>
        </div>
        <div className="PricingPage__demo">
          <a href="https://calendly.com/wubalu/30min" target="_blank">
            <Button variant="contained" color="primary">¿Tienes dudas? Solicita una Demo</Button>
          </a>
        </div>
        <div className="PricingPage__plans">
          <div className="PricingPage__plan">
            <div className="PricingPage__plan__image">
              <img src={starterIcon} alt="Starter" />
            </div>
            <div className="PricingPage__plan__description">
              Perfecto para empezar a ser tendencia
            </div>
            <div className="PricingPage__plan__price">
              <span className="amount">250€</span>
              <span className="terms"> + iva <span className="terms__price">(Primer mes por 100€ + iva)</span></span>
            </div>
            <ul className="PricingPage__plan__features">
              <li>
                <strong>Stories que atraen clientes nuevos:</strong> Contenido generado por tus propios clientes, listo para compartir en redes sociales. (Valorado en 500€/mes)
              </li>
              <li>
                <strong>Panel de resultados en tiempo real:</strong> Monitoriza alcance, stories y clientes captados desde un único lugar.
              </li>
              <li>
                <strong>Validación automática de stories con IA:</strong> Olvídate de revisar stories manualmente, nuestro sistema lo hace por ti. (Valorado en 250€/mes)
              </li>
              <li>
                <strong>App para trabajadores:</strong> Tus empleados validan stories en segundos, sin esfuerzo.
              </li>
              <li>
                <strong>Garantía de 3 meses:</strong> Si no consigues resultados, te devolvemos el dinero.
              </li>
              <li>
                <strong>Kit de inicio:</strong> Incluye todo el material necesario para empezar (Valorado en 60€).
              </li>
              <li>
                <strong>Wubalu Delivery:</strong> Promociona tu restaurante también en pedidos a domicilio.
              </li>
              <li>
                <strong>Reposición gratuita de material cada dos meses.</strong> (Valorado en 30€/mes)
              </li>
              <li>
                <strong>Newsletter con estrategias exclusivas:</strong> Aprende cómo potenciar tu visibilidad. (Valorado en 50€/mes)
              </li>
            </ul>
            <div className="PricingPage__plan__button">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => handleSelectPlan('starter')}
              >
                Seleccionar Plan
              </Button>
            </div>
          </div>

          <div className="PricingPage__plan PricingPage__plan--pro">
            <div className="PricingPage__plan__image PricingPage__plan--pro__image">
              <img src={proIcon} alt="Pro" />
            </div>
            <div className="PricingPage__plan__description">
              El plan definitivo para destacar y maximizar tus resultados.
            </div>
            <div className="PricingPage__plan__price">
              <span className="amount">500€</span>
              <span className="terms"> + iva <span className="terms__price">(Primer mes por 200€ + iva)</span></span>
            </div>
            <div className="PricingPage__plan__includes">Incluye todo lo del Plan Starter, más:</div>
            <ul className="PricingPage__plan__features">
              <li>
                <strong>Asesoramiento personalizado:</strong> Un experto guía tu estrategia inicial para maximizar tus resultados. (Valorado en 200€)
              </li>
              <li>
                <strong>Sesión mensual con un experto:</strong> Optimiza resultados y ajusta estrategias cada mes. (Valorado en 150€/mes)
              </li>
              <li>
                <strong>Mejora tu cuenta de Instagram:</strong> Una sesión mensual con un experto en redes sociales para aumentar tu alcance. (Valorado en 100€/mes)
              </li>
              <li>
                <strong>Soporte prioritario:</strong> Resolvemos tus dudas en menos de 24 horas. (Valorado en 30€/mes)
              </li>
              <li>
                <strong>Formación para tu equipo incluida:</strong> Asegúrate de que todo tu personal aproveche Wubalu al máximo. (Valorado en 150€)
              </li>
              <li>
                <strong>Acceso a nuevas funcionalidades antes que nadie:</strong> Sé el primero en probar lo último de Wubalu.
              </li>
              <li>
                <strong>Promoción destacada en la app de Wubalu:</strong> Aumenta tu visibilidad frente a otros restaurantes.
              </li>
            </ul>
            <div className="PricingPage__plan__button">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => handleSelectPlan('pro')}
              >
                Seleccionar Plan
              </Button>
            </div>
          </div>
        </div>
        <div className="PricingPage__notes">
          <div className="PricingPage__notes__title">
            <div>
              <span>Notas </span>
              <span>importantes</span>
            </div>
            <div className="PricingPage__demo">
              <a href="https://calendly.com/wubalu/30min" target="_blank">
                <Button variant="contained" color="primary">¿Tienes dudas? Solicita una Demo</Button>
              </a>
            </div>
          </div>

          <div className="PricingPage__notes__grid">
            <div className="PricingPage__notes__grid__item">
              <div className="PricingPage__notes__grid__item__header">
                <span role="img" aria-label="Alta">🏆</span>
                <h3>Alta y primer mes</h3>
              </div>
              <p>
                El primer mes solo se cobra el Kit de inicio y puesta en marcha (100€ para Starter y 200€ para Pro+). Esto incluye las reuniones necesarias para la puesta en marcha, la preparación y envío de todo el material necesario. 30 días después empezaría la suscripción del plan con el precio total.
              </p>
            </div>

            <div className="PricingPage__notes__grid__item">
              <div className="PricingPage__notes__grid__item__header">
                <span role="img" aria-label="Puntos">📍</span>
                <h3>Puntos de venta</h3>
              </div>
              <p>
                Todos los planes incluyen un solo punto de venta, cualquier punto de venta extra se cobrará junto con la suscripción al final de cada mes. El coste de un punto de venta extra es de 65€/mes
              </p>
            </div>
          </div>

          <div className="PricingPage__notes__grid">
            <div className="PricingPage__notes__grid__item">
              <div className="PricingPage__notes__grid__item__header">
                <span role="img" aria-label="Pasos">👣</span>
                <h3>Primeros pasos</h3>
              </div>
              <p>
                Una vez selecciones el plan tendrás que crear tu cuenta, añadir el nombre de tu marca y método de pago. Después tendrás acceso a toda la plataforma, podrás dar de alta tus restaurantes y configurar las campañas. Una vez que todo este listo, te enviaremos todo el material a los restaurantes para que puedas empezar a ser la nueva tendencia. En todo momento estaremos disponibles para cualquier duda que puedas tener.
              </p>
            </div>

            <div className="PricingPage__notes__grid__item">
              <div className="PricingPage__notes__grid__item__header">
                <span role="img" aria-label="Garantía">⭐</span>
                <h3>Garantía de Wubalu</h3>
              </div>
              <p>
                La garantía de Wubalu cubre únicamente la suscripción de los tres primeros meses, una vez terminado el tercer mes se dispone de 20 días para pedir la garantía.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
