import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  detail: {},
};

export const slice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setDetail: (state, action) => {
      state.detail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDetail } = slice.actions;

export default slice.reducer;
