export default [
  {
    question: "¿Cómo funciona la garantía de Wubalu?",
    answer:
      "En Wubalu, estamos tan seguros de la efectividad de nuestra herramienta que ofrecemos una garantía de 3 meses. Si después de tres meses no has alcanzado los resultados esperados, te devolvemos todo tu dinero sin hacer preguntas. Queremos que te sientas completamente seguro al invertir en tu negocio con nosotros.",
  },
  {
    question: "¿Hay algún compromiso de permanencia?",
    answer:
      "¡Absolutamente no! En cualquier momento puedes darte de baja sin penalización.",
  },
    {
    question: "¿Necesito experiencia previa o conocimientos técnicos??",
    answer:
      "¡En absoluto! Wubalu está diseñado para ser simple y accesible para todos. No necesitas ser un experto en marketing ni contar con un equipo especializado. Nosotros hacemos el trabajo complejo por ti; tú solo disfrutas de los resultados.",
  },
  {
    question: "¿Cuándo puedo empezar?",
    answer:
      "¡Ahora mismo! En menos de cinco minutos puede tener tu cuenta operativa. Además nosotros te proporcionamos todo el material y formación necesaria para la puesta en marcha. ¡Sin costes extra!",
  },
  {
    question: "¿Cómo ayuda Wubalu a mi restaurante a ganar más clientes?",
    answer:
      "Wubalu convierte a tus comensales en influencers de tu marca. Incentivamos a tus clientes para que compartan stories auténticas en Instagram recomendando tu restaurante. Estas recomendaciones generan confianza, atraen nuevos clientes y aumentan la recurrencia.",
  },
  {
    question:
      "¿Puedo usar el contenido publicado por los usuarios para fines comerciales propios?",
    answer:
      "¡Claro que sí! Los usuarios aceptan unos términos y condiciones que ceden al restaurante todos los derechos de las fotos.",
  },
  {
    question:
      "¿Qué pasa si tengo varios restaurantes?",
    answer:
      "Con Wubalu, puedes gestionar varios restaurantes desde una sola cuenta. El primer restaurante está incluido en tu suscripción, y cada restaurante adicional tiene un coste reducido de solo 65€ al mes. ",
  },
];
