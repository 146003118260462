import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createTheme, ThemeProvider } from "@mui/material";

import useAuth from "./models/auth/useAuth";
import QRScan from "./pages/StoryValidation/QRScan";
import AuthRoute from "./containers/AuthRoute";
import AdminLayout from "./containers/AdminLayout";
import Dashboard from "./pages/Dashboard";
import AdminRoute from "./containers/AdminRoute";
import RestaurantFile from "./pages/Restaurants/Detail";
import WorkersList from "./pages/Workers";
import Invitations from "./pages/Invitations";
import StoryList from "./pages/Stories";
import Instructions from "./pages/Instructions";
import Home from "./pages/Home";
import HowItWorks from "./pages/HowItWorks";
import Restaurants from "./pages/Restaurants";
import DeactivateAccount from "./pages/DeactivateAccount";
import CreateAccount from "./pages/CreateAccount";
import Billing from "./pages/Billing";
import Pricing from "./pages/Princing";

import "./styles.scss";
import RestaurantDetail from "./pages/Restaurants/Detail2";
import WhatIsWubalu from "./pages/WhatIsWubalu";
import useAccounts from "./models/accounts/useAccounts";

const InitialRedirect = () => {
  const {
    user: { restaurant_worker },
  } = useAuth();
  const path =
    restaurant_worker && restaurant_worker.is_admin
      ? "/admin/restaurants"
      : "/admin/story-validation";
  return <Navigate to={path} />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" element={<AdminLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="how-it-works" element={<HowItWorks />} />
      <Route path="what-is-wubalu" element={<WhatIsWubalu />} />
      <Route path="admin/*" element={<AuthRoute />}>
        <Route path="" element={<InitialRedirect />} />
        <Route
          path="restaurants"
          element={
            <AdminRoute>
              <Restaurants />
            </AdminRoute>
          }
        />
        <Route
          path="restaurants/create"
          element={
            <AdminRoute>
              <RestaurantDetail />
            </AdminRoute>
          }
        />
        <Route
          path="restaurants/:id"
          element={
            <AdminRoute>
              <RestaurantDetail />
            </AdminRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <AdminRoute>
              <Dashboard />
            </AdminRoute>
          }
        />
        <Route
          path="workers"
          element={
            <AdminRoute>
              <WorkersList />
            </AdminRoute>
          }
        />
        <Route
          path="stories"
          element={
            <AdminRoute>
              <StoryList />
            </AdminRoute>
          }
        />
        <Route path="story-validation" element={<QRScan />} />
        <Route path="invitations" element={<Invitations />} />
        <Route path="instructions" element={<Instructions />} />
        <Route path="delete-account" element={<DeactivateAccount />} />
        <Route
          path="billing"
          element={
            <AdminRoute>
              <Billing />
            </AdminRoute>
          }
        />
      </Route>
      <Route path="create-account" element={<CreateAccount />} />
      <Route path="pricing" element={<Pricing />} />
    </Route>
  )
);

function App() {
  const { user, isAuthenticated, me } = useAuth();
  const { fetchAccount } = useAccounts();

  useEffect(() => {
    if (user && user.restaurant_worker && user.restaurant_worker.account) {
      fetchAccount(user.restaurant_worker.account);
    }
  }, [user]);

  useEffect(() => {
    if (!isAuthenticated) me();
    const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

    if (analyticsId) {
      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
      script.async = true;
      document.body.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", analyticsId);
    }
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#6854ff",
      },
    },
  });

  return (
    <GoogleOAuthProvider clientId="799471198822-i04f0ncrnkavdpd6fhv3h3ge0jp7v40o.apps.googleusercontent.com">
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
